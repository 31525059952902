export const homeObjOne = {
    id: 'tests',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Health Tests',
    headline: 'Select Any Body Part to Diagnose the Brain Functions',
    description: 'We are a benefit company that provide free online diagnosis of brain functions.',
    buttonLabel: 'Start Testing',
    imgStart: false,
    img: require('../../images/svg-2.svg').default,
    alt: 'Paper',
    dark: false,
    primary: false,
    darkText: true,
    // internalRoute: "/signin"
    externalLink: "https://www.neuroprior.net/"
};

export const homeObjTwo = {
    id: 'research',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: ' Our Research',
    headline: 'Develop a Neuroscience-Inspired AI',
    description: 'Our dedicated efforts in research, collaboration, and community engagement aim to decode the intricacies of the neural realm, all while developing state-of-the-art AI solutions.',
    buttonLabel: 'Learn More',
    imgStart: true,
    img: require('../../images/svg-1.svg').default,
    alt: 'Piggybank',
    dark: false,
    primary: false,
    darkText: true,
    internalRoute: "/research"
    // externalLink: "https://www.neuroprior.com/"
};

export const homeObjFour = {
    id: 'blog',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: ' Our Blog',
    headline: 'Our Philosophy, Innovations and Latest Updates',
    description: 'We tackle the challenges of applying cutting-edge technology to human health.',
    buttonLabel: 'Enter Blog',
    imgStart: false,
    img: require('../../images/svg-6.svg').default,
    alt: 'Money',
    dark: true,
    primary: true,
    darkText: false,
    internalRoute: "/bloghome"
    // externalLink: "https://www.neuroprior.com/"
};

export const homeObjThree = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Our Vision',
    headline: 'Open-Source AI Platform & Biomedical Research',
    description: 'To be at the forefront of neuroscience-inspired artificial intelligence, driving impactful scientific and technological enhancements that amplify human potential and expand our understanding.',
    buttonLabel: 'Our Team',
    imgStart: true,
    img: require('../../images/svg-3.svg').default,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true,
    internalRoute: "/ourteam"
    // externalLink: "https://www.neuroprior.com/"
};
