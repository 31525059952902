import React from 'react'
import Icon1 from '../../images/svg-1.svg'
import Icon2 from '../../images/svg-2.svg'
import Icon3 from '../../images/svg-3.svg'
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements'

const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>Our Services</ServicesH1>
            <ServicesWrapper>
                <ServicesCard as="a" href="https://www.ihealthchain.com/">
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>BMRTDD</ServicesH2>
                    <ServicesP>Blockchain-enhanced medical responsibility traceable distributed database.</ServicesP>
                </ServicesCard>
                {/* <ServicesCard as="a" href="https://researchcopilot.net/"> */}
                <ServicesCard as="a" href="/">
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>Pending</ServicesH2>
                    <ServicesP>Lorem ipsum dolor sit amet consectetur adipiscing elit adipiscing elit.</ServicesP>
                </ServicesCard>
                {/* <ServicesCard as="a" href="https://www.microtrode.com/"> */}
                <ServicesCard as="a" href="/">
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>Pending</ServicesH2>
                    <ServicesP>Lorem ipsum dolor sit amet consectetur adipiscing elit adipiscing elit.</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services