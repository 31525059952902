import React, {useState} from 'react'
import Video from '../../videos/video3.mp4'
import {ScrollButton} from '../MainButtonElements'
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper,ArrowForward, ArrowRight } from './HeroElements'

const HeroSection = () => {
    const[hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }

  return (
    <HeroContainer>
        <HeroBg>
            <VideoBg autoPlay loop muted src={Video} type='video3/mp4' />
        </HeroBg>
        <HeroContent>
            <HeroH1>
                Neurointelligence Labs
            </HeroH1>
            <HeroP>
            To be at the forefront of neuroscience-inspired artificial intelligence, driving impactful scientific innovations and discoveries
            </HeroP>
            <HeroBtnWrapper>
                {/* <ScrollButton to="tests" smooth={true} duration={500} spy={true} exact='true' offset={-60} onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'>
                    Start Testing {hover ? <ArrowForward /> : <ArrowRight />}
                </ScrollButton> */}
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection